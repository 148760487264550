.bg-black{
    p, h1, h2, h3, h4, h5, h6, span, label, legend, ul li, ol li, .card-tag, .date{
        color: $color-white !important;
    }
    .accordion{
        .card{
            border-color: $color-white !important;
            .btn{
                &::before{
                    color: $color-white !important;
                }
            }
        }
    }
    .owl-nav{
        i{
            color: $color-white;
        }
    }
}

.bg-image{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.5);
        overflow: hidden;
    }
    img{
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}
.text-background{
    color: $color-white !important;
}