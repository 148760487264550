.footer{
    padding: rem-calc(30) 0;
    color: $color-white;
    ul{
        list-style: none;
        padding: 0;
        a{
            color: $color-white;
            padding: 0;
        }
    }
    .social{
        a{
            font-size: rem-calc(25);
            color: $color-white;
            &:hover{
                color: $color-primary;
            }
        }
    }
}