.header{
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 2;
    padding: rem-calc(10) 0;
    .inner{
        display: flex;
        width: 100%;
        align-items: center;
    }
    .unit, .primary ul{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .primary{
        ul{
            list-style: none;
            justify-content: flex-end;
            margin-bottom: 0;
            li{
                padding-right: rem-calc(50);
                &:last-child{
                    padding-right: 0;
                }
                a{
                    &:hover{
                        text-decoration: none;
                    }
                    @media (max-width: 1023px) {
                        padding-left: 0;
                        padding-right: 0;
                        font-size: rem-calc(20);
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
                @media (max-width: 1023px) {
                    margin-bottom: rem-calc(20);
                    font-size: rem-calc(20);
                    padding-right: 0;
                }
            }
            @media (max-width: 1023px) {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color-primary;
                padding: rem-calc(30);
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: rem-calc(70) !important;
                flex-direction: column;
                transition: all .25s ease-out;
                opacity: 0;
                transform: translateX(-100%);
            }
            &.toogle{
                opacity: 1;
                transform: translateX(0);
                // display: flex;
                // position: fixed;
                // top: 0;
                // left: 0;
                // width: 100%;
                // height: 100%;
                // background-color: $color-primary;
                // padding: 1.875rem;
                // -ms-flex-direction: column;
                // flex-direction: column;
                // z-index: 2;
            }
        }
        .nav-open-button{
            display: none;
            @media (max-width: 1023px) {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color-white;
                cursor: pointer;
                width: 35px;
                height: 35px;
                font-size: rem-calc(35);
                position: relative;
                z-index: 3;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    transition: transform .2s ease-out;
                    transform-origin: left;
                }
                &::before {
                    top: 5px;
                }
                &::after {
                    bottom: 5px;
                }
                span {
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    transition: opacity .18s ease-out;
                }
            }
            &.toogle{
                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
                span {
                    opacity: 0;
                }
            }
        }
        .dropdown-menu{
            @media (max-width: 1023px) {
                position: relative !important;
                background: transparent;
                border-radius: 0;
                transform: inherit !important;
                border: none;
                width: 100%;
                margin-top: rem-calc(10);
                padding-bottom: 0;
                a{
                    color: $color-white;
                    padding: 0 rem-calc(20);
                    margin-bottom: rem-calc(10);
                    display: block;
                    width: 100%;
                    font-size: rem-calc(18);
                    border-bottom: 1px solid rgba($color-white, 0.3);
                    padding-bottom: rem-calc(10);
                }
            }
        }
    }
    .search-bar{
        margin-left: rem-calc(30);
        @media (max-width: 1023px) {
            display: none;
        }
        fieldset{
            display: flex;
            position: relative;
            &::before{
                content: '\f002';
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 900;
                display: block;
                font-size: 20px;
                position: absolute;
                right: rem-calc(10);
                top: rem-calc(5);
            }
            .field{
                margin-bottom: 0;
            }
        }
        input[type='text']{
            border: none;
            box-shadow: none;
            height: 35px;
            padding: 0 rem-calc(15);
        }
        input[type='submit']{
            position: absolute;
            right: 0;
            text-indent: -9999px;
            background: none;
            border: none;
        }
    }
}