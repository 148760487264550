//Collapse
.accordion{
    .card{
        border: none;
        background: none !important;
        border-bottom: 1px solid $color-black !important;
        overflow: inherit;
        border-radius: 0;
        &-header{
            background: none !important;
            padding: 0;
            border: none !important;
            position: relative;
        }
        &-body{
            padding: 0;
        }
        .btn{
            font-family: 'Moderat';
            background: none !important;
            padding: rem-calc(20) 0;
            font-size: rem-calc(24);
            font-weight: bold;
            color: $color-black !important;
            white-space: initial;
            &::before{
                content: '\f067';
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 900;
                position: absolute;
                left: rem-calc(-50);
                top: rem-calc(30);
                font-size: rem-calc(18);
                @media (max-width: 767px) {
                    left: auto;
                    right: rem-calc(0);
                }
            }
            &:hover, &:focus{
                text-decoration: none;
                outline: 0 !important;
                box-shadow: none;
                border: 2px solid transparent !important;
            }
            &.show{
                &::before{
                    content: '\f068';
                    font-family: 'Font Awesome\ 5 Free';
                    font-weight: 900;
                }
            }
        }
    }
}

//Button
.btn, .action{
    background: $color-primary;
    color: $color-white;
    font-weight: bold;
    border-radius: 0 !important;
    border: 2px solid transparent;
    box-shadow: none;
    padding: rem-calc(10) rem-calc(20);
    margin-bottom: rem-calc(3);
    &:hover,
    &.active{
        color: $color-primary !important;
        background: none;
        border: 2px solid $color-primary;
    }
}

//Input 
.form-control, .select-dropdown{
    background: #EFF0F2 !important;
    height: 48px !important;
    padding: 0 rem-calc(15) !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    width: 100%;
    border: none !important;
}
.field{
    margin-bottom: rem-calc(30);
    .custom-checkbox, .custom-radio{
        display: flex;
        align-items: center;
    }
    input[type=checkbox], input[type=radio]{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

//Modal
.modal{
    &-header{
        border-bottom: none !important;
        padding-bottom: rem-calc(0) !important;
    }
    iframe{
        width: 100%;
    }
}


//pagination
.pagination{
    margin: rem-calc(20) 0;
    display: flex;
    justify-content: flex-end;
    .page-link{
        background: $color-white;
        border: 1px solid $color-grey;
        padding: rem-calc(0) rem-calc(10);
        margin-right: rem-calc(5);
        &:last-child{
            margin-right: 0;
        }
        &.current, &:hover{
            background: $color-primary;
            color: $color-white;
            text-decoration: none;
        }
    }
}

//Form 
.optionset{
    .middleColumn{
        display: flex;
        flex-direction: column-reverse;
        .legend{
            margin-bottom: rem-calc(20);
            font-size: rem-calc(14);
        }
    }
}


//Breadcrumb
.head-breadcrumb{
    background: rgba($color-grey, 0.4);
    .breadcrumb{
        background: transparent;
        border-radius: 0;
    }
}

//Search input
.search-input {
    padding-right: rem-calc(40) !important;
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}

.red-border{
    border: 1px solid #FF0000;
}