.video{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../images/play.svg") no-repeat top center;
        background-size: contain;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        top: 30%;
    }
}
.modal-video{
    .modal{
        &-content{
            background: none;
            border: none;
        }
        &-header{
            padding: 0;
            .close{
                opacity: 1;
                padding: 0;
                color: $color-white;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                position: relative;
                z-index: 3;
                background: $color-primary;
                span{
                    position: relative;
                    bottom: rem-calc(2);
                }
            }
        }
        &-body{
            padding: 0;
        }
        &-dialog{
            max-width: 700px;
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
}