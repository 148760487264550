/* #region Variables */
//Colors Main
$color-white: #ffffff;
$color-black: #000000;
$color-primary: #000DFF;
$color-grey: #DADADA;
$color-secondary:#00FFCE;


//Background main
.bg-white{
    background-color: $color-white !important;
}
.bg-black{
    background-color: $color-black !important;
}
.bg-primary{
    background-color: $color-primary !important;
}
.bg-grey{
    background-color: $color-grey !important;
}
.bg-secondary{
    background-color: $color-secondary !important;
}
//Text color main
.color-white{color:$color-white !important;}
.color-black{color:$color-black !important;}
.color-primary{color:$color-primary !important;}
.color-grey{color:$color-grey !important;}
.color-secondary{color:$color-secondary !important;}
/* #endregion */

/* #region Function */
@function rem-calc($size) {
    $remSize: $size / 16;
    @return #{$remSize}rem;
  }
/* #endregion */

/* #region Global */
html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    scroll-behavior: smooth;
}

body{
    font-family: 'Neue Haas Unica Pro';
    width: 100%;
    font-size: rem-calc(14);
    color: $color-black;
    line-height: rem-calc(28);
    overflow-x: hidden;
    &.fixed{
        position: fixed;
        height: 100%;
        overflow: hidden;
    }
}

*{outline: none!important;}

a{
    color: $color-primary;
    &:hover{
        text-decoration: underline;
        color: $color-primary;
    }
}

.section{
    padding: rem-calc(50) 0;
}

.w-half{
    width: 49.7%;
    @media (max-width:767px) {
        width: 100%;
    }
}

.w-100, .element{
    width: 100% !important;
    height: auto !important;
}

.w-auto{
    width: auto !important;
}

.text-bold{
    font-weight: bold;
}

.text-italic{
    font-style: italic;
}

.text-underline{
    text-decoration: underline;
}

.text-transform{
    text-transform: uppercase;
}

.m-auto{
    margin: 0 auto;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-space-between{
    justify-content: space-between;
}

.align-items-center{
    align-items: center;
}

.flex-direction{
    flex-direction: column;
}

.align{
    &-text{
        &-left{
            justify-content: flex-start;
        }
        &-center{
            justify-content: center;
        }
        &-right{
            justify-content: flex-end;
        }
    }
}

.img-responsive{
    max-width: 100%;
    height: auto;
}

.img{
    width: 100%;
    height: auto !important;
    @media (max-width: 1023px) {
        margin-top: rem-calc(30);
    }
}
.small-width{
    width: 70%;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.narrow{
    width: 70%;
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 rem-calc(15);
    }
}

.no-padding-top{
    padding-top: 0 !important;
}

.no-padding-bottom{
    padding-bottom: 0 !important;
}

.half-padding-top{
    padding-top: rem-calc(50) !important;
}

.half-padding-bottom{
    padding-bottom: rem-calc(50) !important;
}

//Border
.no-border{
    border: none !important;
}

.border-color{
    border: 10px solid $color-black;
}

.border-circle{
    border-radius: 50%;
}

.border-spare{
    border-radius: 45px;
}


/* #endregion */

/* #region Typography */
h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
    font-family: 'Moderat';
    font-weight: 700;
}
.h1,h1{
    font-size: rem-calc(40);
    @media (max-width: 1300px) {
        font-size: rem-calc(50);
    }
    @media (max-width: 767px) {
        font-size: rem-calc(35);
    }
}
.h2,h2{
    font-size: rem-calc(30);
    @media (max-width: 1300px) {
        font-size: rem-calc(28);
    }
    @media (max-width: 767px) {
        font-size: rem-calc(25);
    }
}
.h3,h3{
    font-size: rem-calc(26);
    @media (max-width: 767px) {
        font-size: rem-calc(25);
    }
}
.h4,h4{
    font-size: rem-calc(24);
    @media (max-width: 767px) {
        font-size: rem-calc(20);
    }
}
.h5,h5{
    font-size: rem-calc(20);
    @media (max-width: 767px) {
        font-size: rem-calc(20);
    }
}
.h6,h6{
    font-size: rem-calc(18);
    @media (max-width: 767px) {
        font-size: rem-calc(20);
    }
}
.font-16{
    font-size: rem-calc(16);
    line-height: 20px;
}

.font-15{
    font-size: rem-calc(15);
    line-height: 20px;
}

.font-14{
    font-size: rem-calc(14);
    line-height: 20px;
}

.font-13{
    font-size: rem-calc(13);
    line-height: 20px;
}


.font-12{
    font-size: rem-calc(12);
    line-height: 20px;
}

/* #endregion */ 

/* #region Spacing */
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
    .m-#{$space} {
        margin: #{$space}px !important;
    }
    .p-#{$space} {
        padding: #{$space}px !important;
    }  
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
        }
    
        .p-#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px !important;
        }
    }
}
/* #endregion */  