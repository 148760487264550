.searchResults{
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            margin-bottom: rem-calc(20);
        }
    }
}

.filter{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey;
    margin-bottom: rem-calc(20);
    padding-bottom: rem-calc(20);
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .form-group{
        display: flex;
        align-items: center;
        position: relative;
        label{
            min-width: 100px;
        }
        .btn-times{
            right: rem-calc(40) !important;
            font-size: rem-calc(16) !important;
            display: none;
        }
        .btn-search, .btn-times{
            background: none !important;
            padding: 0 !important;
            position: absolute;
            right: rem-calc(10);
            color: $color-black;
            font-size: rem-calc(25);
            i{
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 900;
            }
        }
    }
}

.list-categories{
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: rem-calc(7);
    li{
        margin-right: rem-calc(10);
        &:last-child{
            margin-right: 0;
        }
    }
}

.featured{
    width: 100%;
    height: 600px;
    overflow: hidden;
    @media (max-width: 1023px) {
        height: 100%;
    }
    img{
        width: 100%;
        height: 600px;
        object-fit: cover;
        @media (max-width: 1023px) {
            height: 100%;
        }
    }
}