@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('../fonts/NeueHaasUnicaPro-Light.woff2') format('woff2'),
        url('../fonts/NeueHaasUnicaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Regular.woff2') format('woff2'),
        url('../fonts/Moderat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular';
    src: url('../fonts/Circular-Bold.woff2') format('woff2'),
        url('../fonts/Circular-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Bold.woff2') format('woff2'),
        url('../fonts/Moderat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('../fonts/NeueHaasUnicaPro-Bold.woff2') format('woff2'),
        url('../fonts/NeueHaasUnicaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('../fonts/NeueHaasUnicaPro-Regular.woff2') format('woff2'),
        url('../fonts/NeueHaasUnicaPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

