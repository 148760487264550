.gallery{
    column-width: 250px;
    column-gap: rem-calc(15);
    width: 100%;
    padding: 0;
    margin: 0;
    .item{
        display: inline-block;
        width: 100%;
        margin-bottom: rem-calc(20);
        .video::before{
            top: rem-calc(-20);
        }
    }
}

.carousel, .tiles-carousel, .video-carousel, .news {
    position: relative;
    img{
        width: 100%;
    }
    .owl{
        &-nav{
            position: absolute;
            width: 100%;
            top: 50%;
            button{
                border: none;
                background: none;
            }
        }
        &-prev{
            position: absolute;
            left: rem-calc(-50);
            @media (max-width: 991px) {
                left: rem-calc(0);
            }
        }
        &-next{
            position: absolute;
            right: rem-calc(-50);
            @media (max-width: 991px) {
                right: rem-calc(0);
            }
        }
    }
    i{
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        @media (max-width: 991px) {
            font-size: rem-calc(30);
        }
    }
}
.navigation-thumbs{
    margin-top: rem-calc(20);
    img{
        width: 100%;
    }
}
.tiles-carousel{
    .owl{
        &-nav{
            top: 30%;
        }
    }
    &.logo-carousel{
        .owl{
            &-nav{
                top: 50%;
            }
        }
    }
}


.video-carousel{
    .owl{
        &-prev{
            position: absolute;
            left: rem-calc(40);
            font-size: rem-calc(30);
            color: $color-white;
        }
        &-next{
            position: absolute;
            right: rem-calc(40);
            font-size: rem-calc(30);
            color: $color-white;
        } 
    }
    img{
        width: 100%;
        object-fit: cover;
    }
}

.news, .tiles-carousel{
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.header-carousel {
    .header-item {
        height: 600px;
        color: $color-white;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            height: 400px;
        }
        &>.container {
            position: relative;
            z-index: 2;
            h1 {
                font-size: rem-calc(65);
                @media (max-width: 767px) {
                    font-size: rem-calc(30);
                }
            }
            .lead {
                @media (max-width: 767px) {
                    font-size: rem-calc(16);
                }
            }
        }
        .header-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.3);
            }
        }
        .header-video {
            position: absolute;
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            z-index: 1;
            video {
                width: 100%;
                height: 100%;
            }
        }
    }
    .owl-dots {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        padding-bottom: rem-calc(20);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            padding-bottom: 0;
        }
        .owl-dot {
            width: 55px;
            height: 50px;
            margin: 0 5px;
            opacity: .4;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            @media (max-width: 767px) {
                width: 40px;
            }
            &.active {
                opacity: 1;
            }
            span {
                width: 100%;
                height: 2px;
                background: #fff;
            }
        }
        
    }
}